
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                

.input {
  display: flex;
  flex-direction: column;
  width: 35rem;
  position: relative;

  &-label {

  }

  &-in {
    height: 4.4rem;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 1.7rem 1.2rem;
    border-radius: 0.8rem;
    overflow: hidden;
    outline: 0;
    margin-top: 0.5rem;
  }
}

.input-search {
  .input-in {
    padding-left: 3.6rem;
    margin-top: 0;
  }
}

.file-select {
  color: #A7A7A7;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  border: 1px solid #E1E4E8;
  position: relative;

  img {
    margin-left: 1.5rem;
    width: 2rem;
  }
}

.file-absolut {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.input-label {
  font-weight: 600;
}


.input-left {
  display: flex;
  flex-direction: column !important;
  width: 100%;
  min-width: 100%;

  textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 10rem;
  }

  > * {
    width: 100% !important;
  }
}

.input-full {
  width: 100% !important;
  justify-content: flex-start !important;

  .input-label {
    width: 15rem !important;
    font-weight: 600;
    padding-right: 2rem;
    box-sizing: border-box;
  }

  .input-in {
    width: 60rem !important;
  }

  > * {
  }
}

.icon-search-input {
  position: absolute;
  left: 1.2rem;
  width: 1.6rem;
  height: 1.6rem;
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
}

.input-center {
  flex-direction: row;
  align-items: center;

  .input-label {
    color: #000;
    margin-right: 1rem;
    font-weight: bold;
  }

  .input-in {
    width: auto;
    background-color: #F8FAFB;
  }
}