
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.personal {

  &-bk {
    height: 11.2rem;
    background-color: rgba(85, 73, 237, 0.18);
    margin-bottom: -5.7rem;
    margin-left: -6.8rem;
    width: calc(100% + 10.6rem);
  }
}

.personal-user {
  display: flex;
}

.personal-icon {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 0.5rem;
  }
}

.personal {
  margin-bottom: 3rem;
}

.personal-icon-src {
  width: 3rem;
  height: 3rem;
  background-size: contain;
  background-position: center center;
  margin-right: 2rem;
}

.personal-img {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 3rem;
}

.personal-info-pos {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.personal-info-contact {
  display: flex;
  margin-top: 1rem;
}

.personal-info-contact-item {
  display: flex;
  flex-direction: column;

  .text {
    &:nth-child(2) {
      margin-top: 0.5rem;
    }
  }

  .text {
    line-height: 155%;
  }

  & + & {
    margin-left: 8rem;
  }
}