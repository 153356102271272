
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.page-indicators-el {
  display: flex;
  align-items: center;
  width: 100%;

  & + & {
    margin-top: 1.65rem;
  }
}

.page-indicators-el-icon {
  width: 4.8rem;
  height: 4.8rem;
}

.page-indicators-el-name {
  margin-left: 1.2rem;
  font-weight: bold;
}

.page-indicators-el-info {
  margin-left: auto;
}

.page-indicators-el-info-price {
  font-weight: 500;
  margin-top: 0.5rem;
}