
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.document-box-page {
  display: flex;
  justify-content: space-between;

  .drop-open {
    &:first-child {
      width: 90rem;
      margin-right: 1.5rem;
      padding-right: 3rem;
      box-sizing: border-box;
    }

    &:last-child {
      width: 50rem;
    }
  }
}

.list-fix-border-dex {
  display: flex;
  align-items: center;
  width: 100%;
  color: #2871FA;

  .border-line-list {
    flex-grow: 2;
    border-top: 2px dotted #2871FA;
    margin: 0 2rem;
    margin-top: 4px;
  }
}

.search-personal-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5rem;

  .btn {
    width: auto;
    margin-left: 1.5rem;
  }

  .input {
    width: 60rem;
  }
}