.model-city {
  box-sizing: border-box;
  padding: 2rem;
  width: 100%;
}

.city-list {
  display: flex;
  flex-direction: column;
}

.city-list__el {
  padding: 1.5rem 0;
  text-align: left;
  & + & {
    border-top: 1px solid #ccc;
  }
}