
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.page-indicators-top {
  border: 1px solid #EDF2F7;
  padding: 2.4rem;
  border-radius: 1.6rem;
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.filter-box-register {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .btn {
    width: auto;
  }

  .btn-head-el {
    margin-top: 2rem;
  }
}

.indicators-list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-center-box {
  display: flex;
  align-items: center;

  img {
    margin-left: 2.4rem;
  }
}

.indicators-list-body {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #A0AEC0;
  padding-bottom: 6.3rem;
  padding-top: 6.3rem;
  box-sizing: border-box;
}

.indicators-list-box {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
}

.indicators-list {
  width: 54.5rem;
  border: 1px solid #EDF2F7;
  padding: 2.4rem;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
}

.page-indicators-left {
  width: 42.7rem;
}

.page-indicators-right {
  margin-left: 4rem;

  img {
    width: 57rem;
  }
}

.page-indicators-number {
  margin-top: 2rem;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

}

.page-pos {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.page-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

}

.org_box {
  img {
    width: 100%;
  }
}

.console-mini {
  margin-bottom: 0;
}