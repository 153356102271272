@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
  $px: (); /* 3 */
  $rem: (); /* 3 */

  @each $value in $values {
    /* 4 */

    @if $value == 0 or $value == auto {
      /* 5 */
      $px: append($px, $value);
      $rem: append($rem, $value);
    } @else {
      $unit: unit($value); /* 6 */
      $val: parseInt($value); /* 6 */

      @if $unit == 'px' {
        /* 7 */
        $px: append($px, $value);
        $rem: append($rem, ($val / 10 + rem));
      }

      @if $unit == 'rem' {
        $px: append($px, ($val * 10 + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {
    /* 8 */
    #{$property}: $px; /* 9 */
  } @else {
    #{$property}: $px; /* 9 */
    #{$property}: $rem; /* 9 */
  }
}
