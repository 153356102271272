
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.work-time-input {
  display: flex;

  .input {
    min-width: 100%;

  }

  &-i1 {
    width: 96rem;
  }

  &-i2 {
    width: 22rem;
  }

  &-i3 {
    width: 22rem;

  }
}

.filter-box__inputs-time {
  min-width: 100%;
  justify-content: space-between;
}