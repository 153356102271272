.input {

  &__box {
    display: flex;
    width: 35.8rem;
    padding: 1rem 1.4rem;
    align-items: center;
    gap: 0.8rem;
    border-radius: 0.8rem;
    border: 1px solid #D0D5DD;
    background-color: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-top: 0.6rem;
    position: relative;
    box-sizing: border-box;

    &.active {
      padding-left: 4.2rem;

    }

    &-icon {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &-in {
      outline: 0;
      width: 100%;
      border: 0;
      background: none;

      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
  }
}