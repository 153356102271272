
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.page-indicators-top {
  border: 1px solid #EDF2F7;
  padding: 2.4rem;
  border-radius: 1.6rem;
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.filter-box-register {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .btn {
    width: auto;
  }

  .btn-head-el {
    margin-top: 2rem;
  }
}

.indicators-list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-center-box {
  display: flex;
  align-items: center;

  img {
    margin-left: 2.4rem;
  }
}

.indicators-list-body {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #A0AEC0;
  padding-bottom: 6.3rem;
  padding-top: 6.3rem;
  box-sizing: border-box;
}

.indicators-list-box {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
}

.indicators-list {
  width: 54.5rem;
  border: 1px solid #EDF2F7;
  padding: 2.4rem;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
}

.page-indicators-left {
  width: 42.7rem;
}

.page-indicators-right {
  margin-left: 4rem;

  img {
    width: 57rem;
  }
}

.page-indicators-number {
  margin-top: 2rem;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

}

.registry-create-type-list {
  display: flex;
}

.page-pos {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.page-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

}

.org_box {
  img {
    width: 100%;
  }
}

.registry-create {
  width: 74.8rem;
  min-height: 10rem;
  background-color: #fff;
  padding: 3rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 0.8rem;
}

.inputs-dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.2rem;
}

.select-dates {
  width: 10rem;
  height: 2.8rem;
  border-radius: 0.8rem;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  padding: 0 1.2rem;
  outline: 0;
  background-image: url("../assets/icon/down.svg");
  background-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  appearance: none;
}

.select-dates::-ms-expand {
  display: none;
}

.btn-center {
  text-align: center;
  justify-content: center;
}


.btn-censel {
  background-color: #A5A5A5 !important;
}

.input-box {
  width: 17.4rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check-box-modal {
  padding: 1.2rem;
  border: 1px solid #cdcdcd;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.6rem;
  color: #A7A7A7;

  .checkbox-input {
    margin-right: 1rem;
  }
}

.open-create-document-type {
  .input {
    width: 100% !important;
  }

  .input + .input {
    margin-top: 0.8rem;
  }

  .input-label {
    width: 12.6rem;
  }

  input {
    width: 17.4rem !important;
    padding: 1rem 1.3rem;
    height: auto;
    background-color: #fff !important;
  }

  .text-s14 {
    font-size: 1.2rem !important;
  }

  .input-label {
    flex-grow: 2;
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #18181826 !important;
  backdrop-filter: blur(5px);
}

.console-mini {
  margin-bottom: 0;
}

.open-create-document {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.registry-create-type-list {
  width: 100%;
}

.close-icon {
  margin-left: auto;
  width: 2rem;
}

.reg-nav {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.registry-types {
  width: calc(50% - 1rem);
  display: flex;
  flex-direction: column;

  & + & {
    margin-left: 2rem;
  }
}


.icon-img-flex-options {
  display: flex;
  align-items: center;

  img + img {
    margin-left: 1rem;
  }
}

.box-el-control-rg {
  background-color: #fff;
  border-radius: 1.2rem;
  padding: 2rem 3rem;
}

.registry-type {
  width: 100%;
  box-sizing: border-box;
  padding: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E1E4E8;
  border-radius: 0.8rem;


  & + & {
    margin-top: 0.8rem;
  }

  b {
    width: 10%;
    flex-grow: 2;
  }

  img {
    width: 2rem;
  }
}

.open-create-document-type {
  .inputs-dates-controls {
    margin-top: 1.2rem;

    input {
      width: 15rem !important;
    }

    .input-label {
      color: #000;
      font-weight: bold;
    }

    .input + .input {
      margin-top: 0 !important;
    }
  }


}