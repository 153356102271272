
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.calendar {
  width: 100%;
  margin: 0 auto;
}

.calendar-box {
  padding: 3rem;
  box-sizing: border-box;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.weekdays {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.weekday {
  font-weight: bold;
  text-align: center;
  width: 4.2rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  justify-content: center;
}

.day {
  width: 4.2rem;
  height: 4.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-calendar-page {
  display: flex;
  align-items: center;

  img {
    width: 2.8rem;
  }
}