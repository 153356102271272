
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.out-box {
  display: flex;
  flex-wrap: wrap;
}

.out-item {
  width: calc(20% - 1.2rem);
  min-height: 5rem;
  background-color: #F0FFFC;
  border-radius: 0.8rem;
  padding: 1.6rem;
  box-sizing: border-box;

  & + & {
    margin-left: 1.2rem;
  }
}

.out-item-icon {
  display: flex;
  align-items: center;
  margin-bottom: 3.4rem;
}

.out-item-icon-src {
  width: 4.5rem;
  height: 4.5rem;
  background-size: contain;
}

.drop-date-out {
  display: flex;
  flex-direction: column;
  padding-left: 4.5rem;
  box-sizing: border-box;
  margin-bottom: 3rem;

  .text + .text {
    margin-top: 1rem;
  }
}