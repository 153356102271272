
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.workplace-space {
  .table-th:first-child {
    padding-left: 3rem;
  }

  .workplace-space .table-th:first-child {

  }
}

.big-color-th {
  .text-s12 {
    font-size: 2rem;
    color: #6418C3 !important;
  }
}