
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.drop-open-head {
  display: flex;
  align-items: center;

  img {
    width: 2.4rem;
    cursor: pointer;
  }
}

.drop-open-name {
  margin: 0 2rem;
}

.drop-open-body {
  margin-top: 2.2rem;
}

.down-icon {
  transform: rotate(0deg);
  transition: 0.6s;
}

.drop-open {
  &.active {
    .down-icon {
      transform: rotate(-180deg);
    }
  }
}