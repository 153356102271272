
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.curse-list {
  width: 110.8rem;
  display: flex;
  flex-wrap: wrap;
  margin-left: -20rem;
}

.curse-list-plus-curse {
  display: flex;
  align-items: center;
  justify-content: center;
}

.curse-single {
  width: 32.6rem;

  margin-left: 4.3rem;
  margin-bottom: 4.3rem;

  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-bottom-right-radius: 2rem;
  border-top-left-radius: 2rem;
  overflow: hidden;
  box-shadow: 0px 0px 15px 1px #e1e1e1;
}

.curse-single-head {
  background-color: #23EABA;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > p:last-child {
    color: #fff;
  }
}

.curse-single-img {
  img {
    height: 20.14rem;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.curse-single-edit {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 20;
}