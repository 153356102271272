
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.date-to {
  display: flex;
  flex-direction: column;

}

.date-to-input {
  border: 1px solid #E2E8F0;
  box-sizing: border-box;
  padding: 1.6rem 1.8rem;
  border-radius: 1.6rem;
  width: 20.4rem;
  height: 4.8rem;
  background-image: url("../assets/icon/calendar.svg");
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: left 1.6rem center;
  margin-top: 1rem;

}

.date-to-input-in {
  outline: 0;
  border: 0;
  background: none;
  width: 100%;
  padding-left: 3rem;
  font-weight: bold;
  color: #2A313C;
}