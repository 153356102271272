
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
@import "./smart-grid.scss";
@import "./text.scss";


@include reset();

button {
  border: none;
  background: none;
}

.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.curse-single-bly {
  position: relative;
  filter: grayscale(50%);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1); /* Полупрозрачный белый фон */
    backdrop-filter: blur(1px); /* Применение размытия с радиусом 10px */
    -webkit-backdrop-filter: blur(1px); /* Для поддержки в Safari */
    z-index: 5;
  }

  &::after {
    background-image: url("https://cdn0.iconfinder.com/data/icons/e-commerce-207/1024/lock-1024.png");
    content: '';
    position: absolute;
    background-size: 20%;
    background-position: center center;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

.center-icon {
  display: flex;
  align-items: center;

  & > a {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
  }

  label {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
  }
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 60rem;
  margin: auto;
  height: 55rem;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.login-form {
  .input-box {
    min-width: 100% !important;
  }
}

body {
  background-color: #F7FAFC;
  min-height: 100vh;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 1.25rem;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid #fff;
  animation: l20-1 0.8s infinite linear alternate,
  l20-2 1.6s infinite linear;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%)
  }
  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%)
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }
  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)
  }
  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%)
  }
  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%)
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg)
  }
  49.99% {
    transform: scaleY(1) rotate(135deg)
  }
  50% {
    transform: scaleY(-1) rotate(0deg)
  }
  100% {
    transform: scaleY(-1) rotate(-135deg)
  }
}

.file-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.btn-def {
  padding: 1.2rem;
  box-sizing: border-box;
  background-color: #5549ED;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 1.2rem;
  overflow: hidden;
  position: relative;

  &.active {
    border: 2px solid #5549ED;
    color: #5549ED;
    background-color: #fff;
    font-weight: 600;
  }
}


.btn-link {
  color: #718096;
  padding: 1.6rem;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 1.2rem;
  overflow: hidden;
  font-weight: 600;
}

.box-fix-nav {
  margin-top: 4rem;
  width: 100%;

  > * + * {
    margin-top: 1rem;
  }
}

.btn-full {
  padding: 1.35rem;
  width: 100%;
}

.btn-work {
  padding: 1.6rem;
  box-sizing: border-box;
  background-color: #FAFAFA;
  cursor: pointer;
  border-radius: 1.2rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  border: 2px solid transparent;

  &.active {
    border: 2px solid #5549ED;
    background-color: #fff;
    font-weight: 600;
  }
}

.drop_list_compony {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 6rem;

  .drop-open {
    width: 45rem;
    margin-bottom: 3rem;
  }
}

.filter-box {
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 3rem;
}


main {
  width: 30%;
  flex-grow: 2;
  background-color: #F8FAFB;
  padding: 0 4rem;
  padding-left: 6.8rem;
}

.main-box {
  background-color: #fff;
}

.site-bar {
  border-right: 1px solid #E8E8E8;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 28.3rem;

  &__head {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 3.9rem;

    .login-form-head-logo {
      margin-bottom: 0;
    }
  }

  &__name {
    box-sizing: border-box;
    padding-left: 2.7rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__menu {
    display: flex;
    flex-direction: column;
  }
}

.site-bat-link-icon {
  width: 2.4rem;
  height: 2.4rem;
  background-color: #718096;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  margin-right: 1.2rem;
  display: flex;
  align-items: center;


}

.site-bar__menu {
  display: flex;
  flex-direction: column;
}

.site-bat-link {
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.6s;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: #718096;
  padding: 1.6rem 3.9rem;
  font-weight: bold;

  span {
    width: 10%;
    flex-grow: 2;
  }

  &.active {
    background-color: #F8F8F8;
    border-radius: 1.2rem;
    color: #5549ED;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -0.2rem;
      top: 50%;
      height: 4rem;
      width: 0.8rem;
      transform: translateY(-50%);
      border-bottom-right-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
      background-color: #5932EA;
    }

    .site-bat-link-icon {
      background-color: #5549ED;
    }
  }

  & + & {
  }

  img {
    width: 2rem;
    margin-right: 1.2rem;
  }

  &__after {
    position: absolute;
    height: 2rem;
    width: 2rem;
    top: 50%;
    right: 0;
    transition: 0.6s;
    transform: translateY(-50%) translateX(125%);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: rotate(45deg);
      background-color: #F6C86E;

    }
  }


  &.active &__after {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: rotate(45deg);
      background-color: #fff;
    }
  }


}

.main-box {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-box-container {
  display: flex;
  flex-grow: 2;
}

.nav-head-user {
  position: absolute;
  box-sizing: border-box;
  right: 0;
  top: 0;
  width: 100%;
  padding: 0rem 1.8rem;
  display: flex;
  justify-content: flex-end;
}

.nav-head-user-src {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  img {
    width: 1.75rem;
    margin-right: 0.5rem;
    position: relative;
    top: 0.15rem
  }
}

.header-profil {
  width: 100%;
  padding: 2.35rem 0;
  padding-right: 8rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E8E8E8;
}

.user-name-info {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.profile-img {
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 100%;
  background-color: #ccc;
  margin-right: 1.2rem;
  background-size: cover;
  background-position: center center;
}

.btn-head-profile {
  padding: 0.8rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  border-radius: 100rem;
}

.diagram-box {
  display: flex;
  align-items: center;
}

.diagram-info {
  font-weight: 600;
  display: flex;
  align-items: center;

  & + & {
    margin-left: 1rem;
  }
}

.work-name {
  line-height: 100%;
}

.btn-head {
  display: flex;
  align-items: center;

  .work-name {
    width: auto;
    margin-right: 2rem;
  }

  & + & {
    margin-left: 3.2rem;
  }
}

.btn-mini {
  padding: 0.7rem 0;
}

.block-flex {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6.4rem;

}

.circle.left {
  position: absolute;
  clip: rect(0, 10rem, 10rem, 3.7rem);
}

.circle.right {
  position: absolute;
  clip: rect(0px, 3.7rem, 10rem, 0px);
}

.dask-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dask-box-body {
  padding-top: 6rem;
}

.radial {
  width: 7.5rem;
  height: 7.5rem;
  position: relative;
}


.radial-text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 10;
  color: #1A202C;
}

.circle span {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 100%;
  position: absolute;
  opacity: 0.5;
  border: 1rem solid #5549ED;
}

.radial-box {
  position: relative;
}

.radial-noactive {
  position: absolute;
  left: 0;
  top: 0;

  span {
    border: 1rem solid #EDF2F7;
  }
}

.circle.left span {
  clip: rect(0px, 3.78rem, 10rem, 0px);
}

.circle.right span {
  clip: rect(0, 10rem, 10rem, 3.78rem);
}


.page {
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


}

.page-filter {
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 1.2rem;
  }
}

.site-bat-link-childen {
  margin-left: 8.4rem;
}

.text-head-box {
  display: flex;
  align-items: center;
}

.btn {
  width: 100%;

  &-def {
    color: #6418C3;
    background-color: #F6EEFF;
    border-radius: 1.4rem;
    font-weight: bold;
    padding: 1.4rem 0;
    text-align: center;
  }

  &-leave {
    color: #343434;
    background-color: #E4FFF9;
    border-radius: 1.4rem;
    font-weight: bold;
    padding: 1.4rem 0;
    text-align: center;
    box-sizing: border-box;

    span {
      font-weight: bold;
      color: #16C098;
    }
  }
}