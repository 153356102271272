
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.page-personal-border {
  display: flex;

  &-site-bar {
    width: 30.4rem;
    margin-right: 3rem;
    padding-right: 3rem;
    border-right: 1px solid #A7A7A7;
  }

  &-main {
    width: 111.6rem;
  }
}

.page-personal-border-site-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > * {
    color: #A7A7A7;

    &.active {
      color: #6418C3;
      padding: 1rem 2rem;
      background-color: #F6EEFF;
      border-radius: 4rem;
    }
  }


  > * + * {
    margin-top: 2rem;
  }
}