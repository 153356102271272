
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.box-nav-head {
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 1.6rem;
  border-top-left-radius: 1.6rem;
  overflow: hidden;
  background-color: #E1E4E8;
}

.box-nav-body {
  box-sizing: border-box;
  padding: 5rem 3rem;
  width: 100%;
}

.box-nav-th-body {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  justify-content: center;
  border-radius: 3.3rem;
}

.box-nav-th {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8D8D8D;
  background-color: #E1E4E8;
  padding-bottom: 3rem;
  cursor: pointer;

  &.active {
    background-color: #FFFFFF;
    border-radius: 1.6rem;

    .box-nav-th-body {
      color: #6418C3;
      background-color: #F6EEFF;
      font-weight: 600;
    }

    .box-nav-th-icon {
      background-color: #6418C3;
    }
  }
}

.box-nav-th-icon {
  width: 3.4rem;
  height: 3.4rem;
  background-color: #8D8D8D;
  margin-right: 1rem;
  mask-size: contain;
  mask-position: center center;
}

.box-nav-th-icon-img {
  background-color: transparent !important;
  background-size: contain;
}

.box-nav-head-first {
  justify-content: flex-start;
}

.box-nav-body {
  min-height: 14rem;
  background-color: #fff;
  border-radius: 1.2rem;
  margin-top: -2rem;
  position: relative;
}