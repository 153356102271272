
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.ci-btn-def {
  position: relative;
  padding: 10px 67px 11px;
  margin: 5rem 0 0 0;
  border: 0;
  background: #E4C1ED;
  border-radius: 100px;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  -webkit-appearance: none;
}

.ci-btn-out {
  @include rem(width, 280px);
  @include rem(height, 72px);
  @include rem(border-radius, 16px);
  padding: 0;
  background: none;
  color: #fff;
  border: 2px solid #FFFFFF;
  transition: 0.6s;

  &:not(&-inv):hover {
    border: 2px solid #fff;
    background-color: #fff;
    color: #0A0A2B;
  }

  &-inv {
    border: 2px solid #0A0A2B;
    color: #0A0A2B;
    transition: 0.6s;

    &:hover {
      border: 2px solid #0A0A2B;
      background-color: #0A0A2B;
      color: #fff;
    }
  }
}

.serving_controls {

}

.serving_controls_body {
  padding: 2rem;
  padding-top: 0;
}

.ci-element-contr_body {
  padding: 1rem;
  width: 100%;
  display: flex;
}

.ci-element-contr {
  &:nth-child(2n){
    .ci-element-contr_body {
      background-color: #F6F6F6;
    }
  }

  .ci-element-contr_body {
    > * {
      width: calc(25% - 2rem);
      flex-grow: 2;
      margin-left: 2rem;
    }
    > *:nth-child(1) {
      width: auto;
    }
  }
}

.btn-def-table {
  border-radius: 0.4rem;
  margin-top: 0;
  padding: 1rem 2.5rem;
  color: #FFBD3E;
  background-color: #fff;

  &.text {
    border: 1px solid #FFBD3E;
  }

  &.active, &:hover {
    background-color: #FFBD3E;
    color: #fff;
  }

  & + & {
    margin-left: 1.5rem;
  }
}
