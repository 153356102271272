
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
@import "../../../assets/scss/smart-grid.scss";

.table {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;

  @include sm-block() {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .table-th-mon, .table-th-demon {
    b {
      color: #000;
    }
  }


  &__header {
    padding: 1rem 1rem;
    box-sizing: border-box;
    @include sm-block() {
      padding: 1rem 0rem;
      .text-s18 {
        font-size: 1.4rem;
      }
    }

  }

  &-tr {
    display: flex;
    justify-content: space-between;
  }

  &-th {
    width: 25%;
    padding: 1.6rem 2.4rem;
    box-sizing: border-box;
    color: #667085;
    display: flex;
    align-items: center;

    .text-s14 {

    }
  }
}

.table__body-main {
  .table-th{
    color: #000 !important;
  }
  .table-th:first-child {
    color: #000;
    font-weight: 600;
    @include sm-block() {
      color: #667085;
      font-weight: normal;
    }
  }
}

.order-edit-controls-off {
  .table__body-main {
    .table-th:first-child {
      color: #101828;
      font-weight: 600;
      @include sm-block() {
        color: #667085;
        font-weight: normal;
      }
    }
  }
}

.sm-controls-el-count-el {
  @include sm-block() {
    color: #000;
    font-weight: bold;
  }
}

.order-edit-controls-on {
  .table__body-main {
    .table-th:first-child {
      color: #101828;
      font-weight: 600;
      @include sm-block() {
        color: #667085;
        font-weight: bold;
      }
    }
  }
}

.table__body-header {

}


.table-th {
  padding: 1.4rem 1.6rem;
  flex-grow: 2;

  border-left: 1px solid #E1E4E8;

  &:first-child {
    padding-left: 4.6rem;
    border-left: 0;
  }
}

.table-th-padding-mini {
  padding-right: 1.4rem;
  padding-left: 1.4rem;
}

.table-th-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.3rem 0rem;
}

.table-tr + .table-tr {
  @include sm-block() {
    border-top: none !important;
  }
}

.order-down__open {
  .table__body-main {
    .table-th {
      background-color: #fff;
    }
  }

  .table-tr {
    @include sm-block() {
      background-color: transparent !important;
      padding: 0.7rem;
    }
  }

  .table {
    box-shadow: none;
    overflow: hidden;


  }
}

.table {
  border: 1px solid #E1E4E8;
  border-radius: 1.2rem;
}

.table-th-product_store {

  .text {
    font-weight: bold;
    color: #000;
  }
}

.table-tr {
  border-top: 1px solid #E1E4E8;
}

.name-photo {

  display: flex;
  align-items: center;
  width: 100%;

  &__photo {
    width: 4rem;
    height: 4rem;
    border-radius: 1rem;
    overflow: hidden;
    background-color: #ccc;
    border: 1px solid #ccc;
    margin-right: 1.2rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  span {
    width: 1rem;
    flex-grow: 2;
  }
}

.options {

  &-def {
    display: flex;
    align-items: center;
  }
}


.table-tr {
  @include sm-block() {
    flex-direction: column;
    padding: 1.5rem;
    box-sizing: border-box;
    border-radius: 1rem;
    padding-bottom: 0;
    & + & {
      margin-top: 1rem;
    }
    .table-th {
      width: 100% !important;
    }
  }

  & + & {
    border-top: 1px solid rgba(102, 112, 133, 0.2);
  }
}

.table-hide {
  border: none !important;
  margin-bottom: 2rem;
}

.table-th-mini-fix {
  .table-th {
    padding: 1.3rem 1.4rem !important;
  }
}

.table-th {
  @include sm-block() {
    padding: 0.25rem 0;

  }
}

.table-tr-fixed {
  display: none;
  position: fixed !important;
  z-index: 10;
  top: 0;

}

.table__body-header {
  &.fixed {
    .table-tr-fixed {
      display: flex;
      @include sm-block() {
        display: none;
      }
    }
  }
}


.table__body-header {
  @include sm-block() {
    display: none;
  }
}

.table-th-sm-block {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  margin: 1rem 0;
  position: relative;
  border-top: 1px solid rgba(106, 106, 106, 0.1);
  padding-bottom: 0;
  margin-bottom: 0;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  &-el {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: #F9FAFB;
    }
  }
}

.pagination-empty {
  & + & {
    display: none;
  }
}

.table-tr-error {
  .table-th {
    background-color: #ffe0e0 !important;
  }
}

.table-tr-empty {
  width: 100%;
  padding: 1.4rem 1.6rem;
  box-sizing: border-box;
  border-bottom: 1px solid #EDF2F7;
}

.table-tr {
  border-top: 1px solid #EDF2F7;
}

.table__body-main {

}