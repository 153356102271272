
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.work-box {
  display: flex;
  width: 100%;
  background-color: #F3F6FB;
  padding: 1.8rem;
  box-sizing: border-box;

  & + & {
    margin-top: 2.9rem;
  }
}

.work {
  display: flex;
  width: 100%;
}

.work-items {
  display: flex;
  align-items: center;
}

.work-items-name {
  width: 13rem;
  font-weight: bold;
}

.work-items-value {
  width: 18rem;
  border: 1px solid #E1E4E8;
  border-radius: 0.8rem;
  padding: 1.2rem 1.3rem;
  box-sizing: border-box;
  margin-left: 1rem;
}

.work-box-drop{
  display: flex;
  width: 100%;
}