
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.curse-list-plus {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.curse-list-plus-btns {
  position: absolute;
  right: 0;
  top: 0;

  .btn-create {
    padding: 1.2rem 3rem;
    padding-right: 4rem;
  }
}

.curse-list-plus-img {
  width: 106.5rem;
}

.curse-list-plus-start {
  justify-content: flex-start;
}

.drop-input-flex {
  width: 33%;

  .input {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .input-label {
    color: #000;
  }

  .input + .input {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.workplace-space-bly {
  .input-in {
    background-color: transparent;
  }
}

.workplace-space-input-margin {
  .drop-input-flex .input + .input {
    margin-top: 3rem;
  }
}


.add-aw-item {
  display: flex;
  align-items: center;
  color: #A7A7A7;
  margin-top: 1rem;

  img {
    margin-right: 1rem;
    width: 1.5rem;
  }
}

.workplace-space-b-top {
  border: none !important;
  border-radius: 0 !important;
  border-top: 1px solid #000 !important;
}

.input-sw-box-ws {
  display: flex;
  align-items: center;
}

.question-input {
  background-color: transparent;
  border: none;
  outline: 0;
  margin-left: 1rem;
  padding: 1rem 0;
  width: 60rem;
}

.close-icon-aw {
  img {
    width: 1.5rem;
  }
}

.input-time {
  display: flex;
  align-items: center;
}

.question-in {
  display: flex;
  align-items: center;
}

.workplace-space-bly {
  background-color: #FBF8FF !important;
}

.img-back-btn {
  margin-bottom: 4rem;
}

.training-white {
  background-color: #fff;
  padding: 3rem 3.5rem;
  box-sizing: border-box;

  > .workplace-space:nth-child(1) {
    background-color: #FBF8FF !important;
  }
}


.course-page {
  display: flex;
  flex-direction: column;
}

.course-page-head {
  display: flex;
  margin-bottom: 3rem;
}

.course-page-img {
  width: 46.8rem;
}

.sw-item {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  input {
    margin-right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }


}

.course-page-aw {
  padding: 2rem;
  margin-top: 2rem;
  border: 1px solid #717171;
  border-radius: 0.8rem;
}

.course-page-text {
  width: 10%;
  flex-grow: 2;
  margin-left: 3rem;
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 2.1rem;
  }

  > p:not(:last-child) {
    text-transform: capitalize;
  }

}

.course-page-img {
  img {
    width: 100%;
    height: 30.7rem;
    object-fit: cover;
    object-position: center center;
  }
}

.workplace-space {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid #ededed;

  .drop-input-flex .input-label {
    width: 15rem;
  }

  & + & {
    margin-top: 2rem;
  }
}

.center-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  > * {
    margin-top: 1rem;
  }
}

.files-list {
  margin-top: 2rem;
}

.file-item {
  & + & {
    margin-top: 1rem;
  }
}

.btn-add-files {
  display: flex;
  align-items: center;
  background-color: #A7A7A7;
  width: 60rem;
  padding: 1rem 0;
  color: #fff;
  margin-top: 2rem;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  position: relative;
}

.file-ab {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.workplace-space-big {
  .input {
    width: 50rem;
    align-items: flex-start;
    flex-direction: column !important;
  }

  .input + .input {
    margin-top: 2rem;
  }

  input {
    min-width: 60rem;
  }

  textarea {
    min-width: 60rem;
    min-height: 30rem;
  }

  input[type='file'] {
    height: auto !important;
    border-radius: 0 !important;
  }
}

.input-in.input-in-file {
  padding: 0;
  border: none;
}

.input-in-file {

}