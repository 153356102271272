
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.news-box-filter {
  display: flex;
  justify-content: flex-end;
}

.news-box-list {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.news-post {
  display: flex;

  border-top: 1px solid #949494;
  padding-top: 3rem;
  margin-bottom: 3rem;
}

.news-post-img {
  width: 68.1rem;
  height: 42rem;
  border-radius: 0.8rem;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.news-post-text {
  width: 70.8rem;
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.news-post-name {
  margin-bottom: 3.3rem;
}

.news-post-desk {

}
