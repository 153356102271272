
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.personal-my {
  display: flex;
  justify-content: space-between;

  .drop-open {
    width: 33%;
  }
}

.drop-item {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 1.7rem;
  }
}

.drop-item-name {
  color: #2871FA;
  font-weight: bold;
}

.drop-item-value {
  margin-left: 1.6rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.icon-about {

  width: 3.2rem;

  & + & {
    margin-left: 1.6rem;
  }
}

.drop-list {
  display: flex;
  flex-direction: column;
}

.upload-file {
  display: flex;
  align-items: center;
  width: 35.2rem;

  & + & {
    margin-top: 2.6rem;
  }
}

.upload-file-icon {
  width: 5.6rem;
  height: 5.6rem;
  background-color: #F6EEFF;
  background-size: 3.2rem;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0.8rem;
  margin-right: 1.6rem;
}

.upload-file-name {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.upload-info {

}