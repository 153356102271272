
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.form-create-project-head-full {
  width: auto;
  text-align: left;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.dask-box-body-head {
  display: flex;
  flex-direction: column;
}

.filter-box {
padding: 4rem ;
}

.filter-box__inputs {
  display: flex;
  justify-content: center;
  max-width: 100%;

  .input + .input {
    margin-left: 2rem;
  }
}

.console-btns {
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;

  .console-name {
    margin-top: 0;
    margin-bottom: 0;
  }

  .btn {
    width: auto !important;
  }
}

.btn-def {

}

.btn-head-el {
  display: flex;
  align-items: center;

  .btn + .btn {
    margin-left: 2rem;
  }
}

.btn-data {
  background-color: #fff;
  border: 1px solid #BCBCBC;
  color: #000;
  font-width: bold;
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  img {
    margin-left: 1rem;
    width: 1.2rem;
  }
}

.btn-filter {
  background-color: #ECEFF1;
  color: #1D2023;
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  img {
    margin-right: 1rem;
    width: 2rem;
  }
}

.btn-create {
  color: #FFFFFF;
  background-color: #5549ED;
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  display: flex;
  box-sizing: border-box;

  align-items: center;

  img {
    margin-right: 1rem;
    width: 2rem;

  }
}

.btn-time {
  color: #677480;
  background-color: #fff;
  padding: 1rem 1.6rem;
  border-radius: 0.8rem;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid #E1E4E8;

  img {
    margin-right: 1rem;
    width: 2rem;

  }
}

.tytorial-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20.6rem;
  position: relative;

  &.active {
    .tytorial {
      background-color: #5549ED;
    }

    .tytorial-name {
      color: #fff;
    }

    .tytorial-desk {
      color: #fff;
    }

    .tytorial-box-number {
      background-color: #5549ED;
    }

    .btn-def {
      border: 1px solid #fff;
      background-color: transparent;
      color: #fff;
    }
  }


  &:last-child {
    &::before {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 14.7rem;
    top: 2.4rem;
    width: 13.8rem;
    background-color: #E2E8F0;
    height: 3px;
    border-radius: 3px;
  }

  & + & {
    margin-left: 2rem;
  }
}

.tytorial-box-number {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 5rem;
  background-color: #FAFAFA;
  border: 1px solid #CBD5E0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #718096;
  margin-bottom: 3.2rem;

}

.tytorial {
  width: 100%;
  border-radius: 1.6rem;
  border: 1px solid #EDF2F7;
  min-height: 10rem;
  padding: 1.6rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 2;
}

.tytorial-img {
  padding: 1.6rem;
  padding-bottom: 0;
}

.tytorial-name {
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.tytorial-desk {
  margin-bottom: 2.4rem;
  padding: 0 0.5rem;
}

.tytorial-nav {
  width: 100%;
  margin-top: auto;
}

.icon-mini {
  width: 2.4rem;
  margin-left: 1rem;
}

.console {
  display: flex;
  margin-bottom: 14rem;

  &-name {
    width: 100%;
    margin-top: 6rem;
    margin-bottom: 2.5rem;
  }

  &-event {
    display: flex;


    &-item {
      display: flex;
      flex-direction: column;
      padding: 2rem;
    }

    &-icon {
      width: 4rem;
      height: 4rem;
      background-size: contain;
      border-radius: 100%;
      background-position: center center;
    }

    &-name {
      color: #425166;
      margin-top: 1.2rem;
    }

    &-content {
      color: #151D48;
      margin-top: 0.6rem;
    }

    &-current {
      width: 54.0rem;
      height: 19.4rem;
      background-color: #F6EEFF;
      border-radius: 1.6rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    &-next {
      width: 18.0rem;
      height: 19.4rem;
      background-color: #FFE2E5;
      margin-left: 4rem;
      box-sizing: border-box;
      border-radius: 1.6rem;
    }


    &-online {
      width: 18.0rem;
      height: 19.4rem;
      background-color: #F3E8FF;
      margin-left: 4rem;
      border-radius: 1.6rem;
      box-sizing: border-box;

    }

  }

  &-post {
    display: flex;
    margin-top: 6rem;

    &-big {
      width: 54rem;
      height: 40.4rem;
      border-radius: 2rem;
      background-color: #ccc;
      display: flex;
      align-items: flex-end;
      color: #fff;
      position: relative;
      overflow: hidden;

      background-size: cover;
      background-position: center center;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.35);
      }

      .console-post-mini-text {
        z-index: 10;
        padding-bottom: 2rem;
        max-width: 35rem;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      margin-left: 4rem;

      &-title {
        margin: 2rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-create {
        padding: 0.9rem 1.4rem;
        background-color: #F6F6F6;
        color: #1A2B88;
        border-radius: 1rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    &-mini {
      display: flex;
      width: 47.8rem;

      & + & {
        margin-top: 3rem;
      }

      &-img {
        border-radius: 2rem;
        width: 22.3rem;
        height: 14.3rem;
        background-color: #ccc;
        background-size: cover;
        background-position: center center;
      }

      &-title {
        margin: 0.5rem 0;
      }

      &-text {
        margin-left: 2rem;
        width: 10%;
        flex-grow: 2;
      }
    }
  }

  &-right {
    margin-top: 2rem;
    width: 38.6rem;
    margin-left: auto;
  }
}

.console-post-list-create-bly {
  color: #6418C3;
}

.console-task-head {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.task-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
}

.console-task {
  margin-top: 6rem;

}

.task {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 2rem;
  }

  &-img {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background-color: #ccc;
    margin-right: 1rem;
  }

  &-text {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding-right: 2rem;
    box-sizing: border-box;
  }
}

.task-text-flex {
  flex-direction: column;
}

.task-text-date {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 100%;

  .text {
    display: flex;
    align-items: center;

    img {
      margin-right: 0.8rem;
    }
  }

  .text + .text {
    margin-left: 1.25rem;
  }
}


.console-task-head-bord {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.console-task-head-date {
  margin-top: 1.5rem;
  width: 100%;

}

.console-task-head-wrap {
  flex-direction: column;
}

.task-box-more {
  margin-top: 2.4rem;
}

.leave-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
}

.leave-info {
  margin: 2rem;
  display: flex;
  align-items: center;
}

.children-box-fix {
  padding-left: 5rem;
}

.leave-info-num {
  margin-left: 1rem;
}

.task-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.task-border {
  border: 1px solid #C2C2C2;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 1.4rem;


  .task-img {
    border-radius: 1.6rem;
    width: 5.6rem;
    height: 5.6rem;
  }
}

.box-event-list-user {
  display: flex;
  align-items: center;
  margin-left: 2.1rem;
  margin-top: 0.5rem;
}

.user-list-event-icon {
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 100%;
  margin-left: -2.1rem;
  background-size: cover;
  background-repeat: no-repeat;
}


.countdown {
  display: flex;
  justify-content: center;
  background-color: #f5efff;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 5.3rem;
  height: 5.3rem;
  border-radius: 1rem;
  padding: 0.5rem;
  box-sizing: border-box;

  & + & {
    margin-left: 1rem;
  }
}

.time {
  font-weight: bold;
  color: #252e5a;
}

.label {
  color: #a6abc8;
  text-transform: uppercase;
}