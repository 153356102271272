$mainSize: 1920;
$mapSize: (
        main: ((s14:14, s15:15, s13:13, s10:10, s25:25, s42:42, s12:12, s16:16, s40:40, s18:18, s22:22, s20:20, s26:26, s33:33, s32:32, s23:23, s24:24, s30:30, s34:34, s36:36, s48:48, s50:50, s56:56, s60:60, s52:52, s35:35, s142:142, s168:168, s96:96), "rem", 10),
);


html {
  font-size: 0.521vw;
  //font-size: 10px;

  //font-size: 0.694vw;
  //font-size: 0.694vw;
  //font-size: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Headland+One&display=swap');

.text-color-def {
  color: #5549ED;
}

.text-un {
  text-decoration: underline;
}

.text {

  font-family: "Manrope", sans-serif;

  //&-head{
  //  font-family: "Headland One", serif;
  //}


  &-bly {
    color: #5549ED;
  }

  &-gray {
    color: #677480;
  }

  &-gray2 {
    color: #A5A5A5;
  }

  @each $key, $sizeStap in $mapSize {
    @if ($key=="main") {
      @each $stapSize, $size in nth($sizeStap, 1) {
        &-#{$stapSize} {
          font-size: #{$size/nth($sizeStap, 3)}rem;
        }
      }
    } @else {
      @media screen and (max-width: #{$key}px) {
        @each $stapSize, $size in nth($sizeStap, 1) {
          &-#{$stapSize} {
            font-size: #{$size/nth($sizeStap, 3)}rem;
          }
        }
      }
    }
  }
}



