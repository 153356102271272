
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.first-page {

  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 4rem;
  box-sizing: border-box;



  &-right {
    width: 46.5rem;
    display: flex;
    align-items: center;
  }
}

.error-message {
  background-color: #FFF5F5;
  padding: 1rem 0;
  width: 100%;
  text-align: center;
  color: #FF4747;
  margin-bottom: 1.6rem;
  border-radius: 0.8rem;
}

.remember-me {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.active {
    .remember-me_input {
      background-image: url("../assets/icon/check.svg");
      background-size: 1.25rem;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #5549ED;
    }
  }
}

.reset-pass-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.4rem 0;
}

.login-form-head-logo {
  text-align: center;
  margin-bottom: 2.4rem;
  font-weight: 900;
}

.input-placeholder-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3.2rem;
  padding-bottom: 7rem;
  box-sizing: border-box;
  z-index: 10;
  background-color: #fff;
  border-radius: 3.2rem;
  border: 1px solid #f1f1f1;

  .login-form-head .text {
  }

  &-head {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;

  }

  form {
    display: flex;
    flex-direction: column;
  }
}

.remember-me_input {
  width: 2rem;
  height: 2rem;
  margin-right: 1.2rem;
  border-radius: 1rem;
  border: 1px solid #E2E8F0;
}

.login-form-footer {
  margin-top: 11.1rem;
  text-align: center;
}

.login-form-link {
  margin-top: 1.6rem;
  text-align: center;

  a {
    color: #000;
    text-decoration: none;
  }
}

.forgot-password {
  color: #4781D8;
  text-decoration: none;
  font-weight: bold;
}

.login-form-head-in {
  font-weight: bold;
}

.input-placeholder {

  margin-bottom: 2.4rem;

}


.input-placeholder-name {
  margin-bottom: 0.8rem;
}

.input-box {
  position: relative;
}

.input-box-pass-off {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  background-image: url("../assets/icon/eye-off.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;

  &.active {
    opacity: 0.5;
  }
}

button {
  cursor: pointer;
  padding: 0;
}

.btn-error {
  opacity: 0.5;
}

.input {

  &-def {
    width: 100%;
    box-sizing: border-box;
    padding: 1.6rem 1.2rem;
    border-radius: 1.2rem;
    border: 1px solid #E2E8F0;
    outline: 0;

    &.input-error {
      border: 1px solid #FF4747;
    }

    & + & {
      margin-top: 1.6rem;
    }

    & .input-box {
      margin-top: 1.6rem;
    }
  }
}

.logo {
  &-def {
    width: 15.6rem;
  }
}

.error-pass {
  width: 100%;
  margin-top: 1.6rem;
}

.error-pass-line {
  width: 0%;
  transition: 0.3s;
  background-color: #FF4747;
  height: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.input-box-flex {
  display: flex;
  align-items: center;
  margin-left: -2rem;
  margin-bottom: 1.6rem;

  > * {
    width: calc(50% - 2rem);
    margin-left: 2rem;
    margin-top: 0 !important;
  }
}