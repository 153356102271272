
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.work-time-input-i11 {
  width: 15rem;
}

.work-time-input-i22 {
  width: 15rem;
}

.filter-box__inputs-time {
  justify-content: flex-start;
}