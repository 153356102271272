
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.meetings-calendar {
  width: 38rem;

  .calendar-box {
    margin-left: 4rem;
    border: 1px solid #ccc;
    border-radius: 0.8rem;
    padding: 2rem;
  }
}


.meetings {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.meetings-left {
  flex-grow: 2;
}

.meetings-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-head-el-mini {
  .btn {
    width: auto;
  }
}