
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                
.checkbox-input {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid #A7A7A7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;

  img {
    width: 1rem;
    height: 1rem;
  }
}

.check-box {
  display: flex;
  align-items: center;
  margin-right: 2rem;

  .checkbox-input {
    margin-left: 1rem;
  }
}