
                  @import "src/assets/scss/rem.scss";
                  @import "src/assets/scss/smart-grid.scss";
                

.input {
  display: flex;
  flex-direction: column;
  width: 35rem;
  position: relative;

  &-select {
    select {
      padding: 0 1rem;
      width: 18.8rem;
    }
  }

  &-label {

  }

  &-in {
    height: 4.4rem;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 1.7rem 1.2rem;
    border-radius: 0.8rem;
    overflow: hidden;
    outline: 0;
    margin-top: 0.5rem;
  }
}

.input-search {
  .input-in {
    padding-left: 3.6rem;
    margin-top: 0;
  }
}

.input-time {

  & + & {
    margin-top: 1rem;
  }
}

.input-mini {
  margin-top: 0 !important;

  select {
    width: 9rem;
    margin-left: 1rem;
  }
}

.icon-search-input {
  position: absolute;
  left: 1.2rem;
  width: 1.6rem;
  height: 1.6rem;
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
}

.input-center {
  flex-direction: row;
  align-items: center;

  .input-label {
    color: #000;
    margin-right: 1rem;
    font-weight: bold;
  }

  .input-in {
    width: auto;
    background-color: #F8FAFB;
  }
}